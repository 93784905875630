import { api } from './trellis-api'
import { composeGetQuery } from '@/services/utils'
import store from '@/store'
import { CAPTABLE_SOURCES_TYPES, SECURITY_CERTIFICAT_TEMPLATE_NAME, ISSUER_SECURITY_CERTIFICATE_NAME, ISSUED_SECURITY_ACTIONS, NOTES_DOCUMENT_API_TYPE } from '@/constants'

import * as captableService from '@/components/common/captable/services/captable'
import * as commonService from '@/components/common/mixins/index'

export const generateCertificate = async (data) => {
  const source = store.getters['auth/source']
  const url = source === CAPTABLE_SOURCES_TYPES.ISSUER ? 'issuer/security-certificate' : 'security-certificate'
  const apiResponse = await api.post(url, data)
  return apiResponse.data
}

export const voidCertificate = async (data) => {
  const source = store.getters['auth/source']
  const url = source === CAPTABLE_SOURCES_TYPES.ISSUER ? 'issuer/security-certificate/void' : 'security-certificate/void'
  const apiResponse = await api.post(url, data)
  return apiResponse.data
}
export const getHoldersList = async (captableId, options) => {
  const params = composeGetQuery(options)
  const apiResponse = await api.get(`organization/${captableId}/holder`, { params }, {
  })
  return apiResponse.data
}

export const inviteHolder = async (captableId, data) => {
  const apiResponse = await api.post(`organization/${captableId}/holder`, data)
  return apiResponse.data
}

export const updateSecurityCetrificate = async (captableId, termDocType, securityDocType, termID, issuedSecurityId, registrationName, issuedNumber) => {
  const certTempDetails = await getCertificateDetails(captableId, termDocType, termID, SECURITY_CERTIFICAT_TEMPLATE_NAME, false)
  const issuedCertDetails = await getCertificateDetails(captableId, securityDocType, issuedSecurityId, ISSUER_SECURITY_CERTIFICATE_NAME, true)
  if (certTempDetails && certTempDetails.location) {
    const certTemp = await getCertificateTemplate(certTempDetails.id)
    const certId = issuedCertDetails?.id
    return updateCertificate(issuedNumber, certId, certTemp, registrationName)
  }
}

const getCertificateDetails = async (captableId, docType, id, docName, isIssuedCert) => {
  const resp = await captableService.getDocuments(captableId, docType, id)
  const docList = resp?.data?.documents || []
  let docDetails = docList.find(doc => doc.name.indexOf(docName) > -1)
  if (isIssuedCert && !docDetails) {
    const newCertResp = await captableService.createDocument(captableId, docType, id, { name: docName })
    docDetails = newCertResp?.data
  }
  return docDetails
}

const getCertificateTemplate = async (id) => {
  const resp = await captableService.downloadDoc(id)
  return resp?.data?.file
}

const getReqData = (certTemp, registrationName, issuedNumber, isVoid) => {
  const reqData = {
    document_template: certTemp,
  }
  if (!isVoid) {
    reqData.certificate_number = registrationName
    reqData.number_of_shares = commonService.$number(issuedNumber)
  }
  return reqData
}

const getUpdatedCertificateFile = async (certTemp, registrationName, issuedNumber) => {
  let resp = null
  if (issuedNumber > 0) {
    const data = getReqData(certTemp, registrationName, issuedNumber, false)
    resp = await generateCertificate(data)
  } else {
    const data = getReqData(certTemp, registrationName, issuedNumber, true)
    resp = await voidCertificate(data)
  }
  return dataURLtoFile(resp?.file, ISSUER_SECURITY_CERTIFICATE_NAME)
}

const updateCertificate = async (issuedNumber, certId, certTemp, registrationName) => {
  const file = await getUpdatedCertificateFile(certTemp, registrationName, issuedNumber)
  if (file) {
    return captableService.uploadDocument(certId, file)
  } else {
    throw new Error('Unable to update certificate.')
  }
}

const dataURLtoFile = (dataurl, filename) => {
  dataurl = `data:image/png;base64,${dataurl}`
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const getServiceRequestList = async (captableId, options) => {
  const source = store.getters['auth/source']
  const params = composeGetQuery(options)
  const url = source === CAPTABLE_SOURCES_TYPES.ISSUER ? `issuer/service-request?captable_id=${captableId}` : `service-request?captable_id=${captableId}`
  const resp = await api.get(url, { params })
  return resp.data
}

export const updateServiceRequestList = async (srid, data) => {
  const source = store.getters['auth/source']
  const url = source === CAPTABLE_SOURCES_TYPES.ISSUER ? `issuer/service-request/${srid}` : `service-request/${srid}`
  const resp = await api.patch(url, data)
  return resp.data
}

export const createServiceRequest = async (securityType, actionType, captableId, securityId, details) => {
  const data = {
    security_type: securityType,
    action_type: actionType,
    captable_id: captableId,
    security_id: securityId,
    details: details,
  }
  const source = store.getters['auth/source']
  const isSuperAdmin = store.getters['auth/isSuperAdmin']
  if (!isSuperAdmin) {
    const url = source === CAPTABLE_SOURCES_TYPES.ISSUER ? 'issuer/service-request' : 'service-request'
    const resp = await api.post(url, data)
    return resp.data
  } else {
    return await approveServiceRequest(actionType, securityType, captableId, details)
  }
}

export const approveServiceRequest = async (actionType, securityType, captableId, details, isDraft) => {
  if (!isDraft) {
    const resp = await performAction(actionType, securityType, captableId, details.certificatePayload.securityId, details.payload)
    return await updateRequestCertificate(actionType, details.certificatePayload, resp?.data?.id)
  } else {
    return await performAction(actionType, securityType, captableId, details.certificatePayload.securityId, details.payload)
  }
}

export const performAction = async (actionType, securityType, captableId, issuedShareId, payload) => {
  switch (actionType) {
    case ISSUED_SECURITY_ACTIONS.TRANSFER: return getSecurityTransferAction(securityType, captableId, issuedShareId, payload)
    case ISSUED_SECURITY_ACTIONS.MODIFY: return getModifyAction(securityType, captableId, issuedShareId, payload)
    case ISSUED_SECURITY_ACTIONS.CANCEL: return getCancelAction(securityType, captableId, issuedShareId, payload)
    case ISSUED_SECURITY_ACTIONS.EXERCISE: return getExerciseAction(securityType, captableId, issuedShareId, payload)
    case ISSUED_SECURITY_ACTIONS.REPURCHASE: return captableService.repurchaseShare(captableId, issuedShareId, payload)
    case ISSUED_SECURITY_ACTIONS.TERMINATE: return captableService.terminateOption(captableId, issuedShareId, payload)
    case ISSUED_SECURITY_ACTIONS.DELETE: return getDeleteAction(securityType, captableId, issuedShareId)
  }
}

export const getSecurityTransferAction = (securityType, captableId, issuedShareId, payload) => {
  switch (securityType) {
    case NOTES_DOCUMENT_API_TYPE.SHARES: return captableService.transferShare(captableId, issuedShareId, payload)
  }
}

export const getModifyAction = (securityType, captableId, issuedShareId, payload) => {
  switch (securityType) {
    case NOTES_DOCUMENT_API_TYPE.SHARES: return captableService.modifyShare(captableId, issuedShareId, payload)
    case NOTES_DOCUMENT_API_TYPE.WARRANTS: return captableService.modifyWarrant(captableId, issuedShareId, payload)
    case NOTES_DOCUMENT_API_TYPE.CONVERTIBLES: return captableService.modifyConvertible(captableId, issuedShareId, payload)
    case NOTES_DOCUMENT_API_TYPE.OPTIONS: return captableService.modifyOption(captableId, issuedShareId, payload)
  }
}

export const getCancelAction = (securityType, captableId, issuedShareId, payload) => {
  switch (securityType) {
    case NOTES_DOCUMENT_API_TYPE.SHARES: return captableService.cancelShare(captableId, issuedShareId, payload)
    case NOTES_DOCUMENT_API_TYPE.WARRANTS: return captableService.cancelWarrant(captableId, issuedShareId, payload)
    case NOTES_DOCUMENT_API_TYPE.CONVERTIBLES: return captableService.cancelConvertible(captableId, issuedShareId, payload)
    case NOTES_DOCUMENT_API_TYPE.OPTIONS: return captableService.cancelOption(captableId, issuedShareId, payload)
  }
}

export const getExerciseAction = (securityType, captableId, issuedShareId, payload) => {
  switch (securityType) {
    case NOTES_DOCUMENT_API_TYPE.WARRANTS: return captableService.exerciseWarrant(captableId, issuedShareId, payload)
    case NOTES_DOCUMENT_API_TYPE.OPTIONS: return captableService.exerciseOption(captableId, issuedShareId, payload)
  }
}

export const getDeleteAction = (securityType, captableId, issuedShareId) => {
  switch (securityType) {
    case NOTES_DOCUMENT_API_TYPE.SHARES: return captableService.deleteShare(captableId, issuedShareId)
    case NOTES_DOCUMENT_API_TYPE.WARRANTS: return captableService.deleteWarrant(captableId, issuedShareId)
    case NOTES_DOCUMENT_API_TYPE.CONVERTIBLES: return captableService.deleteConvertible(captableId, issuedShareId)
    case NOTES_DOCUMENT_API_TYPE.OPTIONS: return captableService.deleteOption(captableId, issuedShareId)
  }
}

export const updateRequestCertificate = async (actionType, certificateData, newSecurityId) => {
  let resp
  switch (actionType) {
    case ISSUED_SECURITY_ACTIONS.TRANSFER:
      resp = await updateSecurityCetrificate(certificateData.captableId, certificateData.termDocType, certificateData.securityDocType, certificateData.termID, certificateData.securityId, certificateData.registrationName, certificateData.remainingNumber)
      resp = await updateSecurityCetrificate(certificateData.captableId, certificateData.termDocType, certificateData.securityDocType, certificateData.termID, newSecurityId, certificateData.newRegistrationName, certificateData.issuedNumber)
      break
    case ISSUED_SECURITY_ACTIONS.CANCEL:
      resp = await updateSecurityCetrificate(certificateData.captableId, certificateData.termDocType, certificateData.securityDocType, certificateData.termID, certificateData.securityId, certificateData.registrationName, certificateData.remainingNumber)
      break
    default:
      resp = await updateSecurityCetrificate(certificateData.captableId, certificateData.termDocType, certificateData.securityDocType, certificateData.termID, certificateData.securityId, certificateData.registrationName, certificateData.issuedNumber)
      break
  }
  return resp
}

export const getAllUserList = async (captableId, options) => {
  const params = composeGetQuery(options)
  const url = `organization/${captableId}/admin-user`
  const resp = await api.get(url, { params })
  return resp.data
}

export const getCaptableRoleList = async () => {
  const url = 'admin-roles'
  const resp = await api.get(url)
  return resp.data
}

export const createCaptableUser = async (captableId, data) => {
  const url = `organization/${captableId}/admin-user`
  const resp = api.post(url, data)
  return resp.data
}

export const updateCaptableUserRoles = async (captableId, trellisId, data) => {
  const url = `organization/${captableId}/admin-user/${trellisId}`
  return api.patch(url, data)
}

export const getServiceRequestSuccessMessage = () => {
  const isSuperAdmin = store.getters['auth/isSuperAdmin']
  if (isSuperAdmin) {
    return 'Action performed successfully!'
  } else {
    return 'Request created successfully.'
  }
}
